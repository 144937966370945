import { toast as toastify } from "react-toastify";
import Toast from "../components/layouts/Toast";

/**
 * @param {{ 
 * headerContent: any,
 * bodyContent: any,
 * type: 'primary'|'secondary'|'danger'|'warning'|'success',
 * options: toastify.ToastOptions
 * }} param0 
 */
export function toast({ headerContent, bodyContent, type, options }) {
    toastify(Toast, {
        data: {
            headerContent,
            bodyContent
        },
        type,
        ...(options??{}),
        role: 'alertdialog'
    });
}
