import React, { createContext, useEffect, useReducer, useRef } from 'react'
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { jwtDecode } from 'jwt-decode'
import api from '../helper/api'
import Loader from '../components/layouts/Loader'

const {
    REACT_APP_FIREBASE_APIKEY,
    REACT_APP_FIREBASE_AUTHDOMAIN,
    REACT_APP_FIREBASE_PROJECTID,
    REACT_APP_FIREBASE_STORAGEBUCKET,
    REACT_APP_FIREBASE_MESSAGINGSENDERID,
    REACT_APP_FIREBASE_APPID,
} = process.env;

const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_APIKEY,
    authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: REACT_APP_FIREBASE_PROJECTID,
    storageBucket: REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: REACT_APP_FIREBASE_APPID,
}

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        const clearToken = accessToken.replace('Bearer ', '');
        localStorage.setItem('accessToken', clearToken);
        api.defaults.headers.common.Authorization = `Bearer ${clearToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete api.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialized: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: (email, password) => Promise.resolve(),
    logout: () => { },
    register: (email, username, password) => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const { current: app } = useRef(initializeApp(firebaseConfig));
    const { current: auth } = useRef(getAuth(app));

    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        const { user } = await signInWithEmailAndPassword(auth, email, password);
        const token = await user.getIdToken();
        setSession(token);
        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const register = async (email, username, password) => {
        throw new Error('Not implemented');
    }

    const logout = async () => {
        await auth.signOut();
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    const goToLogin = () => {
        const { pathname } = window.location;
        console.log('AuthContext.goToLogin', { pathname })
        const permitedPaths = ['/anunciar-imovel']
        if (pathname !== '/login' && permitedPaths.includes(pathname)) {
            window.location.href = '/login'
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                if (accessToken && isValidToken(accessToken)) {
                    const decoded = jwtDecode(accessToken);
                    setSession(accessToken);
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: decoded,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                    goToLogin();
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
                goToLogin();
            }
        })();
    }, [])

    if (!state.isInitialized) {
        return <Loader />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
