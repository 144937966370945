import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from "./contexts/AuthContext";
import { Router } from "./Router";
import { RealEstateProvider } from "./contexts/RealEstateContext";

function App() {
  return (
    <AuthProvider>
      <ToastContainer position='bottom-right' className='toast-container' icon={false} closeButton={false} />
      <RealEstateProvider>
        <Router/>
      </RealEstateProvider>
    </AuthProvider>
  );
}

export default App;
