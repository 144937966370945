import { ValidationError } from 'yup';
import { toast } from './toastHelper';

function processRatingStars(rating) {
    let stars = [];
    for (let i = 0; i < 5; i++) {
        stars.push(<i className="fas fa-star" key={i}></i>);
    }
    if (rating && rating > 0) {
        for (let i = 0; i <= rating - 1; i++) {
            stars[i] = <i className="fas fa-star active" key={i}></i>;
        }
    }
    return stars;
}

function navigateToElement(id) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
}

function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
}

/**
 * @param {() => void} notifyCopy 
 */
function copyCurrentUrl(notifyCopy) {
    const url = window.location.href;
    if (navigator.share) {
      navigator.share({
        title: 'Ver Anúncio',
        text: 'Ver Anúncio do Imóvel',
        url,
      }).catch((e) => {
        console.error('Erro ao compartilhar link', e);
        copyToClipboard(url);
        if (notifyCopy) notifyCopy();
      });
    } else {
      copyToClipboard(url);
      if (notifyCopy) notifyCopy();
    }
}

function toastBaseMessage(message, headers) {
    const errorData = `${message}\n
    X-Amz-Apigw-Id: ${headers['X-Amz-Apigw-Id']}\n
    X-Amzn-Requestid: ${headers['X-Amzn-Requestid'] || headers['Apigw-Requestid']}\n
    X-Amzn-Trace-Id: ${headers['X-Amzn-Trace-Id']}\n
    Request-Date: ${new Date().getTime()}
    `;
    const onClick = () => {
      copyToClipboard(errorData);
      toast({
        headerContent: 'Mensagem de Erro Copiada',
        bodyContent: 'Envie a mensagem copiada para reportar o erro.',
        type: 'primary',
        options: {
          autoClose: 1000,
          closeOnClick: true,
        }
      })
    }
    return {
      element: <div>
        {message} <br/>
        <strong><small className="text-muted">Clique Aqui Copiar a Mensagem de Erro</small></strong>
      </div>,
      onClick,
    }
}

function errorHandler(title, defaultMessage, error, mappedMessage = {}) {
  if (error?.response?.status) {
    let message = (mappedMessage || {})[error?.response?.status] ?? defaultMessage;
    if (error?.response?.status === 422) {
      const validationError = new ValidationError(error.response.data);
      message = (
        <>
          {message}
          <br />
          {validationError.errors.map((e) => (
            <>
              {e}
              <br />
            </>
          ))}
        </>
      );
    }
    const body = toastBaseMessage(message, error.response.headers);
    toast({
      headerContent: title,
      bodyContent: body.element,
      type: 'danger',
      options: {
        autoClose: 5000,
        closeOnClick: false,
        onClick: body.onClick,
      }
  });
    throw error;
  }
  if (error && error instanceof ValidationError) {
    let message = (
      <>
        {defaultMessage}
        <br />
        {error.errors.map((e) => (
          <>
            {e}
            <br />
          </>
        ))}
      </>
    );
    const body = toastBaseMessage(message, {});
    toast({
      headerContent: title,
      bodyContent: body.element,
      type: 'danger',
      options: {
        autoClose: 5000,
        closeOnClick: false,
        onClick: body.onClick,
      }
  });
    throw error;
  }
  const body = toastBaseMessage(defaultMessage, {});
  toast({
      headerContent: title,
      bodyContent: body.element,
      type: 'danger',
      options: {
        autoClose: 5000,
        closeOnClick: false,
        onClick: body.onClick,
      }
  });
  throw error;
}

const CountryState = [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MG',
  'MS',
  'MT',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SE',
  'SP',
  'TO',
]

const parseAmount = (amount) => {
  if (amount === null || amount === undefined) {
    return null;
  }
  if (typeof amount === 'number') {
    return amount;
  }
  return parseFloat(amount.replace(/\./g, '').replace(',', '.'));
}

const getAmount = (price, specifically) => {
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format((price).toFixed(2));
  if (specifically === 'Aluguel') {
    return amount + '/mês';
  }
  return amount;
}


const getMeter = (meter) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'unit',
    unit: 'meter'
  }).format(meter).replace('m', 'm²')
}

function removeEmptyString(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== '') {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

function getLocationCensured(location) {
  return `${location.street}, ${location.district}, ${location.city} - ${location.state}`;
}

function getLocationNonCensured(location, details = true) {
  const firstLine = `${location.zipCode}, ${location.street}, ${location.streetNumber}, ${location.complement}, ${location.district}, ${location.city} - ${location.state}`
  return <>
    {firstLine.replace(/, ,/g, ', ').replace(/undefined,/g, '')}
    {details && (<><br/>
    Condomínio: {location.townhouse ?? '-'}, Ponto de Referência: {location.reference ?? '-'}</>)}
  </>;
}


function getLocationNonCensuredText(location, details = true) {
  const firstLine = `${location.zipCode}, ${location.street}, ${location.streetNumber}, ${location.complement}, ${location.district}, ${location.city} - ${location.state}`
  return firstLine.replace(/, ,/g, ', ').replace(/undefined,/g, '').concat(
    details ? (`Condomínio: ${location.townhouse ?? '-'}, Ponto de Referência: ${location.reference ?? '-'}`) : ''
  )
}

function formatPhone(phone) {
  return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
}

export {
    processRatingStars,
    navigateToElement,
    copyToClipboard,
    copyCurrentUrl,
    errorHandler,
    toastBaseMessage,
    CountryState,
    parseAmount,
    getAmount,
    getMeter,
    removeEmptyString,
    getLocationCensured,
    getLocationNonCensured,
    getLocationNonCensuredText,
    formatPhone,
};