import { createContext, useEffect, useMemo, useState } from "react";
import { errorHandler } from "../helper/helper";
import api from "../helper/api";

/**
 * Real Estate context
 * @typedef {Object} RealEstate
 * @property {string} id
 * @property {string} slug
 * @property {string} name
 * @property {string} content
 * @property {string} specifically
 * @property {string} status
 * @property {string} type
 * @property {number} price
 * @property {number} priceRent
 * @property {number} buildingArea
 * @property {number} landArea
 * @property {number} iptu
 * @property {number} condominium
 * @property {Object} location
 * @property {string} location.zipCode
 * @property {string} location.street
 * @property {string} location.district
 * @property {string} location.streetNumber
 * @property {string} location.complement
 * @property {string} location.city
 * @property {string} location.state
 * @property {string[]} features
 * @property {Object} details
 * @property {number} details.beds
 * @property {number} details.baths
 * @property {number} details.parkingSpaces
 * @property {number} details.built
 * @property {string[]} images
 * 
 * @typedef {Object} CreateParams
 * @property {string} name
 * @property {string} content
 * @property {string} specifically
 * @property {string} type
 * @property {number} price
 * @property {number} priceRent
 * @property {number} buildingArea
 * @property {number} landArea
 * @property {Object} location
 * @property {string} location.zipCode
 * @property {string} location.street
 * @property {string} location.district
 * @property {string} location.streetNumber
 * @property {string} location.complement
 * @property {string} location.city
 * @property {string} location.state
 * @property {string[]} features
 * @property {Object} details
 * @property {number} details.beds
 * @property {number} details.baths
 * @property {number} details.parkingSpaces
 * @property {number} details.built
 * @property {string[]} images
 * 
 * @typedef {Object} CreateResponse
 * @property {string} id
 * @property {string} slug
 * @property {{ url: string; expires: number; }[]} presignedUrls
 * 
 * @typedef {Object} UpdateParams
 * @property {string} id
 * @property {string} slug
 * @property {string} name
 * @property {string} content
 * @property {string} specifically
 * @property {string} type
 * @property {number} price
 * @property {number} buildingArea
 * @property {number} landArea
 * @property {Object} location
 * @property {string} location.zipCode
 * @property {string} location.street
 * @property {string} location.district
 * @property {string} location.streetNumber
 * @property {string} location.complement
 * @property {string} location.city
 * @property {string} location.state
 * @property {string[]} features
 * @property {Object} details
 * @property {number} details.beds
 * @property {number} details.baths
 * @property {number} details.parkingSpaces
 * @property {number} details.built
 * 
 * @typedef {Object} ListParams
 * @property {number} limit
 * @property {number} offset
 * @property {number} priceMin
 * @property {number} priceMax
 * @property {number} landAreaMin
 * @property {number} landAreaMax
 * @property {number} buildingAreaMin
 * @property {number} buildingAreaMax
 * @property {number} beds
 * @property {number} baths
 * @property {string} type
 * @property {number} parkingSpaces
 * @property {string} locationState
 * @property {string} locationCity
 * @property {string} status
 * @property {string} id
 * 
 * @typedef {Object} MovePositionImageParams
 * @property {string} id
 * @property {string[]} images
 */

const RealEstateContext = createContext({
    loading: {
        creating: false,
        getting: false,
        updating: false,
        listing: false,
        basicListing: false,
        randomizing: false,
        xml: false,
        images: {
            adding: false,
            removing: false,
            moving: false,
        },
        locations: {
            listing: false,
        }
    },
    /**
     * @param {CreateParams} params
     * @returns {Promise<CreateResponse>}
     */
    create: (params) => Promise.resolve(),
    /**
     * @param {string} id 
     * @returns {Promise<RealEstate>}
     */
    get: (id) => Promise.resolve(),
    /**
     * @param {UpdateParams} params 
     * @returns {Promise<void>}
     */
    update: (params) => Promise.resolve(),
    /**
     * @param {ListParams} params
     * @param {boolean} save
     * @returns {Promise<{ data: RealEstate[], size: number }>}
     */
    list: (params, save) => Promise.resolve(),
    /**
     * @param {boolean} save
     * @returns {Promise<RealEstate[]>}
     */
    basicList: (save) => Promise.resolve(),
    /**
     * @type {RealEstate[]}
     */
    realEstateBasicList: [],
    /**
     * @type {{data: RealEstate[], size: number}}
     */
    realEstateList: [],
    /**
     * @type {{data: RealEstate[], size: number}}
     */
    realEstateListRecents: [],
    /**
     * @returns {Promise<RealEstate>}
     */
    random: () => Promise.resolve(),
    xml: () => Promise.resolve(),
    images: {
        /**
         * @param {{id: string, images: string[]}} params
         * @returns {Promise<{presignedUrls: { url: string; expires: number; }[]}>}
         */
        add: (params) => Promise.resolve(),
        /**
         * @param {{id: string, images: string[]}} params
         * @returns {Promise<void>}
         */
        remove: (params) => Promise.resolve(),
        /**
         * @param {MovePositionImageParams} params 
         * @returns {Promise<void>}
         */
        move: (params) => Promise.resolve(),
    },
    /**
     * @type {{state: string, city: string}[]}
     */
    locationsList: [],
    locations: {
        /**
         * @returns {Promise<{[state:string]: {[city: string]: number}}>}
         */
        list: () => Promise.resolve(),
    }
})

export const RealEstateProvider = ({ children }) => {
    const [loading, setLoading] = useState({
        creating: false,
        getting: false,
        updating: false,
        listing: false,
        basicListing: false,
        xml: false,
        images: {
            adding: false,
            removing: false,
            moving: false,
        },
        locations: {
            listing: false,
        }
    });

    /**
     * @param {CreateParams} params
     * @returns {Promise<CreateResponse>}
     */
    const create = async (params) => {
        try {
            setLoading((old) => ({ ...old, creating: true }));
            const { data } = await api.post("/real-estate", params);
            return data;
        } catch (error) {
            errorHandler("Cadastro de Imóvel", "Erro ao tentar cadastrar imóvel", error);
        } finally {
            setLoading((old) => ({ ...old, creating: false }));
        }
    }

    /**
     * @param {string} id 
     * @returns {Promise<RealEstate>}
     */
    const get = async (id) => {
        try {
            setLoading((old) => ({ ...old, getting: true }));
            const { data } = await api.get(`/real-estate/${id}`);
            return data;
        } catch (error) {
            errorHandler("Buscar Imóvel", "Erro ao tentar buscar imóvel", error);
        } finally {
            setLoading((old) => ({ ...old, getting: false }));
        }
    }

    const xml = async () => {
        try {
            setLoading((old) => ({ ...old, xml: true }));
            await api.get(`/real-estate/xml`, {
                responseType: 'blob',
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);
            
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'imoveis.xml'); //or any other extension
                document.body.appendChild(link);
                link.click();
            
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
        } catch (error) {
            errorHandler("XML Imóveis", "Erro ao tentar baixar arquivo XML com os imóveis", error);
        } finally {
            setLoading((old) => ({ ...old, xml: false }));
        }
    }

    /**
     * @returns {Promise<RealEstate>}
     */
    const random = async () => {
        try {
            setLoading((old) => ({ ...old, randomizing: true }));
            const { data } = await api.get(`/real-estate/random`);
            return data;
        } catch (error) {
            errorHandler("Buscar Imóvel", "Erro ao tentar gerar imóvel aleatório", error);
        } finally {
            setLoading((old) => ({ ...old, randomizing: false }));
        }
    }

    /**
     * @param {UpdateParams} params 
     * @returns {Promise<void>}
     */
    const update = async (params) => {
        try {
            setLoading((old) => ({ ...old, updating: true }));
            await api.put(`/real-estate/${params.id}`, params);
        } catch (error) {
            errorHandler("Atualizar Imóvel", "Erro ao tentar atualizar imóvel", error);
        } finally {
            setLoading((old) => ({ ...old, updating: false }));
        }
    }

    const [realEstateList, setRealEstateList] = useState({ data:[], size: 0 });
    const [realEstateListRecents, setRealEstateListRecents] = useState({ data:[], size: 0 });
    /**
     * @param {ListParams} params
     * @returns {Promise<{ data: RealEstate[], size: number }>}
     */
    const list = async (params, save) => {
        try {
            setLoading((old) => ({ ...old, listing: true }));
            const { data } = await api.get("/real-estate", { params });
            save && data && setRealEstateList(data);
            return data;
        } catch (error) {
            errorHandler("Listar Imóveis", "Erro ao tentar listar imóveis", error);
        } finally {
            setLoading((old) => ({ ...old, listing: false }));
        }
    }

    const [realEstateBasicList, setRealEstateBasicList] = useState([]);

    /**
     * @returns {Promise<RealEstate[]>}
     */
    const basicList = async (save) => {
        try {
            setLoading((old) => ({ ...old, basicListing: true }));
            const { data } = await api.get("/real-estate/list");
            save && data && setRealEstateBasicList(data??[]);
            return data;
        } catch (error) {
            errorHandler("Listar Imóveis", "Erro ao tentar listar imóveis", error);
        } finally {
            setLoading((old) => ({ ...old, basicListing: false }));
        }
    }

    const images = {
        /**
         * @param {{id: string, images: string[]}} params
         * @returns {{presignedUrls: { url: string; expires: number; }[]}}
         */
        add: async (params) => {
            try {
                setLoading((old) => ({ ...old, images: { ...old.images, adding: true } }));
                const { data } = await api.post(`/real-estate/${params.id}/images`, params);
                return data;
            } catch (error) {
                errorHandler("Adicionar Imagens", "Erro ao tentar adicionar imagens", error);
            } finally {
                setLoading((old) => ({ ...old, images: { ...old.images, adding: false } }));
            }
        },
        /**
         * @param {{id: string, images: string[]}} params
         * @returns {Promise<void>}
         */
        remove: async (params) => {
            try {
                setLoading((old) => ({ ...old, images: { ...old.images, removing: true } }));
                await api.patch(`/real-estate/${params.id}/images`, params);
            } catch (error) {
                errorHandler("Remover Imagens", "Erro ao tentar remover imagens", error);
            } finally {
                setLoading((old) => ({ ...old, images: { ...old.images, removing: false } }));
            }
        },
        /**
         * @param {MovePositionImageParams} params 
         * @returns {Promise<void>}
         */
        move: async (params) => {
            try {
                setLoading((old) => ({ ...old, images: { ...old.images, moving: true } }));
                await api.put(`/real-estate/${params.id}/images`, params);
            } catch (error) {
                errorHandler("Mover Imagens", "Erro ao tentar mover a posição das imagens", error);
            } finally {
                setLoading((old) => ({ ...old, images: { ...old.images, moving: false } }));
            }
        },
    }


    const [locationsRecord, setLocationsRecord] = useState({});
    const locationsList = useMemo(() => {
        if (!locationsRecord) return [];
        return Object.keys(locationsRecord).map((state) => {
            return Object.keys(locationsRecord[state])
                .filter((city) => locationsRecord[state][city] > 0)
                .map((city) => {
                    return { state, city }
                });
        }).flat();
    }, [locationsRecord])
    const locations = {
        list: async () => {
            try {
                setLoading((old) => ({ ...old, locations: { ...old.locations, listing: true } }));
                const { data } = await api.get('/real-estate/locations');
                data && setLocationsRecord(data);
                return data;
            } catch (error) {
                errorHandler("Buscar Localizações", "Erro ao tentar buscar localizações", error);
            } finally {
                setLoading((old) => ({ ...old, locations: { ...old.locations, listing: false } }));
            }
        }
    }

    useEffect(() => {
        if (!loading.locations.listing && locationsList.length === 0) locations.list();
        if (realEstateListRecents.size === 0) list({ limit: 5, offset: 0, status: 'Pendente' }).then((data) => setRealEstateListRecents(data));
    }, [])

    return (
        <RealEstateContext.Provider value={{
            create,
            get,
            update,
            list,
            realEstateList,
            realEstateListRecents,
            random,
            images,
            locations,
            locationsList,
            loading,
            xml,
            basicList,
            realEstateBasicList,
        }}>
            {children}
        </RealEstateContext.Provider>
    )
}

export default RealEstateContext;
