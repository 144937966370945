import BootstrapToast from 'react-bootstrap/Toast';

function Toast({ closeToast, data, toastProps }) {
  return (
    <BootstrapToast className={`custom-${toastProps.type??'primary'}`}>
      <BootstrapToast.Header closeButton={false}>
        <strong>{data.headerContent}</strong>
      </BootstrapToast.Header>
      <BootstrapToast.Body>
        {data.bodyContent}
      </BootstrapToast.Body>
    </BootstrapToast>
  );
}

export default Toast;